<template>
  <navigation index="userManage" ref="navigation">
    <div class="topnavigation" style="gap: 10px">
      <el-button @click="AddAccount()"> {{  tools.GetLanguageValue('web.创建用户')  }} </el-button>
      <el-input
        style="width: 150px"
        v-model="PaddingStoreUser.Search"
        :placeholder="tools.GetLanguageValue('web.请输入用户名')"
      ></el-input>

      <el-button type="primary" @click="GetStoreUserList"> {{  tools.GetLanguageValue('web.查询')  }}</el-button>
    </div>
    <div>
      <el-table
        border
        :data="AccountDatas"
        style="width: 100%"
        :height="ContentHeight + 'px'"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column :label="tools.GetLanguageValue('web.帐号')" width="180">
          <template #header>  {{  tools.GetLanguageValue('web.帐号')  }} </template>
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span style="margin-left: 10px">{{ scope.row.Account }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="tools.GetLanguageValue('web.姓名')" width="180" prop="Name">
        </el-table-column>
        <el-table-column :label="tools.GetLanguageValue('web.手机')" width="180" prop="Phone">
        </el-table-column>
        <el-table-column :label="tools.GetLanguageValue('web.邮箱')" width="180" prop="Email">
        </el-table-column>
        <el-table-column :label="tools.GetLanguageValue('web.账号状态')" width="120" prop="ForbiddenFormat">
          <template #default="scope">
            <el-tag v-if="!scope.row.Forbidden" type="success">
              {{ scope.row.ForbiddenFormat }}
            </el-tag>
            <el-tag v-else type="danger">
              {{ scope.row.ForbiddenFormat }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column :label="tools.GetLanguageValue('web.操作')">
          <template #default="scope">
            <el-button
              type="primary"
              size="small"
              @click="AccountEdit(scope.$index, scope.row)"
              >{{  tools.GetLanguageValue('web.编辑')  }}</el-button
            >

            <el-button
              size="small"
              v-if="!scope.row.Forbidden"
              @click="UserForbidden(scope.$index, scope.row)"
              type="warning"
              > {{  tools.GetLanguageValue('web.禁用')  }}</el-button
            >
            <el-button
              size="small"
              v-if="scope.row.Forbidden"
              @click="UserRemoveForbidden(scope.$index, scope.row)"
              type="success"
              > {{  tools.GetLanguageValue('web.解禁')  }}</el-button
            >
            <el-button
              size="small"
              @click="AccountDelete(scope.$index, scope.row)"
              type="danger"
              > {{  tools.GetLanguageValue('web.删除')  }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div
        style="
          height: 50px;
          display: flex;
          justify-items: center;
          gap: 10px;
          padding: 0px 10px;
        "
      >
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="CurrentChange"
          :page-size="padding.Size"
          :total="padding.Count"
        >
        </el-pagination>
      </div>
    </div>
  </navigation>

  <!-- 修改新增用户 -->
  <el-dialog
    v-model="EditOrAddWindow"
    :title="IsAdd ? '新增' : '修改'"
    width="850"
    :before-close="EditOrAddWindowClose"
    :show-close="false"
  >
    <el-form ref="form" style="margin: 20px 30px">
      <el-form-item>
        <el-input
          v-model="AccountModel.Account"
          :placeholder="tools.GetLanguageValue('web.请输入账号')"
          :disabled="!IsAdd"
        >
          <template #prefix>
            <el-icon><UserFilled /></el-icon>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="AccountModel.Name" :placeholder="tools.GetLanguageValue('web.请输入姓名')">
          <template #prefix>
            <el-icon><DocumentCopy /></el-icon>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-col :span="11">
          <el-input v-model="AccountModel.Password" :placeholder="tools.GetLanguageValue('web.请输入密码')">
            <template #prefix>
              <el-icon><Lock /></el-icon>
            </template>
          </el-input>
        </el-col>
        <el-col :span="2"> </el-col>
        <el-col :span="11" v-if="!IsAdd">  {{  tools.GetLanguageValue('web.不修改请留空')  }} </el-col>
      </el-form-item>
      <el-form-item>
        <el-input v-model="AccountModel.Phone" :placeholder="tools.GetLanguageValue('web.请输入手机号')">
          <template #prefix>
            <el-icon><PhoneFilled /></el-icon>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="AccountModel.Email" :placeholder="tools.GetLanguageValue('web.请输入邮箱')">
          <template #prefix>
            <el-icon><Message /></el-icon>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item :label="tools.GetLanguageValue('web.账号角色')"  >
        <el-select v-model="AccountModel.RolesArray"   multiple :placeholder="tools.GetLanguageValue('web.请选择账号角色')">
          <el-option :label="tools.GetLanguageValue('web.员工')" value="StoreUser"> {{  tools.GetLanguageValue('web.员工')  }}</el-option>
          <el-option :label="tools.GetLanguageValue('web.管理员')"   value="StoreAdmin"> {{  tools.GetLanguageValue('web.管理员')  }}</el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="EditOrAddWindowClose"> {{  tools.GetLanguageValue('web.关闭')  }}</el-button>
        <el-button type="primary" @click="EditOrAddSave()"> {{  tools.GetLanguageValue('web.确定')  }}</el-button>
      </span>
    </template>
  </el-dialog>

  <div class="content"></div>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import "../../../assets/css/commons.css";
import { ElMessageBox, ElMessage } from "element-plus";
import navigation from "../../../components/Modules/Navigation.vue";
import {
  Search,
  Plus,
  UserFilled,
  Calendar,
  CircleCheck,
  Close,
  Lock,
  DocumentCopy,
  FolderAdd,
  Document,
  Refresh,
  Avatar,
  Postcard,
  CreditCard,
  Position,
  PhoneFilled,
  Star,
  Message,
} from "@element-plus/icons-vue";
export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      tools:tools,
      clientHeight: window.innerHeight,
      width: window.innerWidth,

      StoreList: [], //商店列表
      AccountDatas: [],
      AccountModel: {}, // 选中用户
      EditOrAddWindow: false, // 修改或者新增窗口
      IsAdd: false, // 是否是新增

      RoleDatas: [],

      NewPassword: "",
      UpdatePasswordDisplay: false,

      padding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
      PaddingStoreUser: {
        Search: "",
        where: {},
      },
      StoreUserBindWindow: false,
      StoreUserOpt: {}, //当前选中商店用户
      StoreUserBindWindowActiveName: "first",

      InvoiceSignatureItems: [],
      ContentHeight: 600,
    };
  },
  name: "App",
  mounted() {
    this.GetStoreUserList();

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.ContentHeight = document.documentElement.clientHeight - 130;
        this.$refs.navigation.onresize();
      })();
    };
    this.$refs.navigation.onresize();
    this.ContentHeight = this.clientHeight - 130;
  },
  components: {
    navigation,
    Position,
    PhoneFilled,
    Lock,
    Star,
    PhoneFilled,
    Message,
    UserFilled,
    DocumentCopy,
  },
  methods: {
    //解禁禁用账户
    UserRemoveForbidden(index, item) {
      axios.apiMethod(
        "/stores/Account/UserRemoveForbidden",
        "get",
        {
          userId: item.Id,
        },
        (result) => {
          if (result.Data.IsSuccess) {
            ElMessage({
              type: "success",
              dangerouslyUseHTMLString: true,
              message: "操作成功",
            });

            this.GetStoreUserList();
          } else {
            ElMessage({
              dangerouslyUseHTMLString: true,
              message: result.Data.Msg,
            });
          }
        }
      );
    },
    //禁用账户
    UserForbidden(index, item) {
      axios.apiMethod(
        "/stores/Account/UserForbidden",
        "get",
        {
          userId: item.Id,
        },
        (result) => {
          if (result.Data.IsSuccess) {
            ElMessage({
              type: "success",
              dangerouslyUseHTMLString: true,
              message: "操作成功",
            });

            this.GetStoreUserList();
          } else {
            ElMessage({
              dangerouslyUseHTMLString: true,
              message: result.Data.Msg,
            });
          }
        }
      );
    },

    //修改窗口
    EditOrAddWindowClose() {
      this.EditOrAddWindow = false;
    },
    // 保存
    EditOrAddSave() {
      const param = this.AccountModel;
      axios.apiMethod(
        "/stores/Account/InsertStoreUser",
        "post",
        param,
        (result) => {
          if (result.Data.IsSuccess) {
            this.EditOrAddWindowClose();
            this.GetStoreUserList();
          } else {
            ElMessage({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    // 创建用户
    AddAccount() {
      this.AccountModel = {
        Email: "",
        Name: "",
        Phone: "",
        Password: "", 
        StoreId: "",
      };
      this.IsAdd = true;
      this.EditOrAddWindow = true;
    },
    // 编辑
    AccountEdit(index, row) {
      this.EditOrAddWindow = true;
      this.AccountModel = row;
      this.IsAdd = false;
    },

    // 删除
    AccountDelete(index, row) {
      ElMessageBox.confirm(
        "确定要删除" + '<span style="color:red">' + row.Name + "</span>" + "吗",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/Account/DeleteAccount?userId=" + row.Id,
            "get",
            {},
            (result) => {
              if (result.Data.IsSuccess) {
                ElMessageBox({
                  title: "提示",
                  message: "删除成功",
                  type: "success",
                });
                this.GetStoreUserList();
                return;
              }

              ElMessageBox({
                title: "提示",
                message: result.Data.Msg,
                type: "error",
              });
            }
          );
        })
        .catch(() => {
          // catch error
        });
    },
    //刷新列表
    CurrentChange(index) {
      this.PaddingStoreUser.Page = index;
      this.GetStoreUserList();
    },
    // 获取用户列表
    GetStoreUserList() {
      this.PaddingStoreUser.Where = {
        Name: "",
        Account: "",
      };

      axios.apiMethod(
        "/stores/Account/GetStoreUserList",
        "post",
        this.PaddingStoreUser,
        (result) => {
          if (result.Code == 200) {
            this.AccountDatas = result.Data.Datas;
            this.PaddingStoreUser.Page = result.Data.Page;
            this.PaddingStoreUser.Size = result.Data.Size;
            this.PaddingStoreUser.Count = result.Data.Count;
          }
        }
      );
    },
  },
};
</script>

<style scoped>
</style>
